
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_carousel_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Carousel/index.vue'
import BlokkliComponent_from_library_index from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_accordeon_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Accordeon/index.vue'
import BlokkliComponent_button_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Button/index.vue'
import BlokkliComponent_call_to_action_index from '/builds/bazinga/mfg/frontend/components/Paragraph/CallToAction/index.vue'
import BlokkliComponent_dynamic_list_event_index from '/builds/bazinga/mfg/frontend/components/Paragraph/DynamicList/Event/index.vue'
import BlokkliComponent_dynamic_list_exhibition_index from '/builds/bazinga/mfg/frontend/components/Paragraph/DynamicList/Exhibition/index.vue'
import BlokkliComponent_icon_text_item_index from '/builds/bazinga/mfg/frontend/components/Paragraph/IconTextItem/index.vue'
import BlokkliComponent_icon_text_list_index from '/builds/bazinga/mfg/frontend/components/Paragraph/IconTextList/index.vue'
import BlokkliComponent_image_grid from '/builds/bazinga/mfg/frontend/components/Paragraph/Image/grid.vue'
import BlokkliComponent_image_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_image_list_index from '/builds/bazinga/mfg/frontend/components/Paragraph/ImageList/index.vue'
import BlokkliComponent_locations_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Locations/index.vue'
import BlokkliComponent_quote_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_teaser_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Teaser/index.vue'
import BlokkliComponent_teaser_list_index from '/builds/bazinga/mfg/frontend/components/Paragraph/TeaserList/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_video_index from '/builds/bazinga/mfg/frontend/components/Paragraph/Video/index.vue'
import BlokkliComponent_visitor_table_index from '/builds/bazinga/mfg/frontend/components/Paragraph/VisitorTable/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/mfg/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_carousel: BlokkliComponent_carousel_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_accordeon: BlokkliComponent_accordeon_index,
  block_button: BlokkliComponent_button_index,
  block_call_to_action: BlokkliComponent_call_to_action_index,
  block_dynamic_list_event: BlokkliComponent_dynamic_list_event_index,
  block_dynamic_list_exhibition: BlokkliComponent_dynamic_list_exhibition_index,
  block_icon_text_item: BlokkliComponent_icon_text_item_index,
  block_icon_text_list: BlokkliComponent_icon_text_list_index,
  block_image__field_list_type_grid: BlokkliComponent_image_grid,
  block_image: BlokkliComponent_image_index,
  block_image_list: BlokkliComponent_image_list_index,
  block_locations: BlokkliComponent_locations_index,
  block_quote: BlokkliComponent_quote_index,
  block_teaser: BlokkliComponent_teaser_index,
  block_teaser_list: BlokkliComponent_teaser_list_index,
  block_text: BlokkliComponent_text_index,
  block_video: BlokkliComponent_video_index,
  block_visitor_table: BlokkliComponent_visitor_table_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_event_exhibition from '/builds/bazinga/mfg/frontend/components/Paragraph/BlokkliFragment/EventExhibition/index.vue'
import BlokkliFragmentComponent_target_groups from '/builds/bazinga/mfg/frontend/components/Paragraph/BlokkliFragment/TargetGroups/index.vue'
import BlokkliFragmentComponent_Test from '/builds/bazinga/mfg/frontend/components/Paragraph/BlokkliFragment/FooBar/index.vue'

const globalFragments: Record<string, any> = {
  fragment_event_exhibition: BlokkliFragmentComponent_event_exhibition,
  fragment_target_groups: BlokkliFragmentComponent_target_groups,
  fragment_Test: BlokkliFragmentComponent_Test
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
